<template>
    <preview-container :ad-data="adData">
        <div class="image-holder">
            <template v-if="isStaticTopSnap">
                <img
                    v-if="type === 'image'"
                    class="preview-image"
                    :src="mediaUrl">

                <div
                    v-if="!hidePlayIcon && type === 'video'"
                    class="play-wrapper"
                    @click="beginPlay">
                    <div class="play">
                        <icon
                            size="40"
                            color="white"
                            name="audio-play" />
                    </div>
                </div>
                <video-player
                    v-if="type === 'video'"
                    :key="playerKey"
                    :src="mediaUrl"
                    :controls="false"
                    :autoplay="playVideo"
                    :muted="muted"
                    color="white"
                    class="preview-video" />
            </template>
            <template v-else>
                <product
                    v-if="primaryProduct"
                    :ad-data="adData"
                    :product="primaryProduct" />
            </template>
        </div>
        <div class="bottom-desc">
            Ad
        </div>
        <div class="bottom-items">
            <a
                v-for="(item, index) in bottomItems"
                :key="index"
                :href="item.link"
                target="_blank"
                class="bottom-item">
                <img :src="item.image">
            </a>
        </div>
        <div class="bottom-block">
            <div class="bottom-dash" />
            <div class="bottom-visit">
                <a
                    :href="ctaLink"
                    target="_blank">
                    {{ ctaText }}
                </a>
            </div>
            <div class="bottom-icon bottom-left-icon">
                <icon
                    size="25"
                    color="white"
                    name="snapchat-camera" />
            </div>
            <div class="bottom-icon bottom-right-icon">
                <icon
                    size="20"
                    color="white"
                    name="snapchat-arrow" />
            </div>
        </div>
    </preview-container>
</template>

<script>
import PreviewContainer from './PreviewContainer';
import Product from './Product';
import Icon from '@/components/globals/Icon';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import { primaryText, SNAPCHAT } from '@/components/ad-studio/store/constants';
import VideoPlayer from '@/components/globals/VideoPlayer/index';

export default {
    name: 'SnapchatCollectionPreview',
    components: {
        Icon,
        Product,
        PreviewContainer,
        VideoPlayer
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        },
        muted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hidePlayIcon: false,
            playVideo: false,
            playerKey: 0
        };
    },
    computed: {
        isDynamic() {
            return this.adData?.render_type === 'DYNAMIC' || this.adData?.products?.length > 1;
        },
        primaryProduct() {
            return this.adData.products[
                Math.floor(
                    Math.random() * this.adData.products.length
                )
            ];
        },
        mediaUrl() {
            return this.adData?.creatives?.[0]?.assets?.[0]?.url
                ?? this.adData?.products?.[0]?.primary_image
                ?? '/img/ad-preview-placeholders/coming-soon-phone-size.jpg';
        },
        sanitizedPrimaryText() {
            return this.adData?.body_text.slice(0, lengthValidatorConstants[primaryText][SNAPCHAT].maxLength);
        },
        type() {
            return this.adData?.creatives?.[0]?.assets?.[0]?.asset_type?.name;
        },
        creatives() {
            return this.adData?.creatives;
        },
        creativeElements() {
            return this.adData?.platform_specific_fields?.creative_elements ?? [];
        },
        isStaticTopSnap() {
            return this.adData?.render_type === 'STATIC';
        },
        bottomItems() {
            // If its not dynamic then we get the media url and cta url from the creative elements
            if (!this.isDynamic) {
                // The first item is the primary asset and not shown in the carousel
                // Loop over all the remaining items and return the image and link
                return this.adData?.creatives?.map((creativeElement) => {
                    return {
                        image: creativeElement?.assets?.[0]?.url,
                        link: creativeElement?.cta_link
                    };
                }).slice(1);
            }

            const placeholders = new Array(this.creativeElements.length).fill({
                image: '/img/ad-preview-placeholders/small-square.png',
                link: ''
            });

            if (this.isDynamic && this.adData?.products?.length) {

                const primaryIndex = this.adData.products.findIndex(product => (
                    product === this.primaryProduct
                ));

                this.adData.products.slice(primaryIndex, 1);

                return placeholders.map((item, index) => {
                    const product = this.adData?.products?.[index + 1];
                    if (product) {
                        return {
                            image: product.primary_image,
                            link: product.link
                        };
                    }
                    return item;
                });
            }

            return placeholders;
        },
        ctaLink() {
            if (this.primaryProduct) {
                return this.primaryProduct.link ?? '';
            }

            return this.adData?.creatives?.[0]?.cta_link ?? '';
        },
        ctaText() {
            const ctaText = this.adData?.creatives?.[0]?.cta_text || this.adData?.media_items?.[0]?.cta_text;
            return ctaText?.toLowerCase() === 'learn more' ? 'More' : ctaText;
        }
    },
    created() {
        this.checkIfPreviewIsValid();
    },
    methods: {
        checkIfPreviewIsValid() {
            if (this.type === 'video') {
                this.$emit('set-video-type');
            }
            if (!this.isDynamic && !this.mediaUrl) {
                throw new TypeError('This type of ad is not supported!');
            }
        },
        beginPlay() {
            this.hidePlayIcon = true;
            this.playVideo = true;
            this.playerKey += 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.image-holder {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 74px;
    display: flex;
    align-items: center;
    z-index: 2;
}
.preview-image {
    width: 100%;
    display: inline-block;
    height: auto;
}

.preview-video {
    width: 100%;
    height: auto;
}
.bottom-block{
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    height: 74px;
}
.bottom-dash {
    width: 35px;
    height: 5px;
    background: $gray-dark;
    margin: 6px auto;
    border-radius: 5px;
}
.bottom-icon {
    position: absolute;
    top: 17px;
    background-color: $gray-dark;
    border-radius: 25px;
    height: 51px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom-left-icon {
    left: 10px;
}
.bottom-right-icon {
    right: 10px;
}
.bottom-visit {
    color: black;
    display: inline-block;
    width: calc(100% - 160px);
    font-weight: 700;
    border-radius: 25px;
    background: white;
}
.bottom-visit a {
    text-decoration: none;
    color: black;
    display: block;
    font-size: 18px;
    min-height: 51px;
    padding: 12px 0;
}
.bottom-desc {
    position: absolute;
    bottom: 91px;
    right: 10px;
    color: $white;
    z-index: 2;
    font-size: 14px;
    text-shadow: 1px 1px 10px $black;
}
.bottom-items {
    position: absolute;
    bottom: 89px;
    left: 13px;
    z-index: 2;
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    padding: 13px 10px;
    background: rgba(143, 158, 166, 0.2);
    border-radius: 10px;
}
.bottom-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $white;
    border-radius: 10px;
    overflow: hidden;
    height: 70px;
    margin: 0 3px;
    img {
        width: 100%;
        max-width: 120px;
        height: auto;
    }
}

.play-wrapper {
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    cursor: pointer;
    z-index: 3;
    .play {
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after {
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}
</style>
